import React, {useState} from 'react';
import classes from './Content.module.css'
import NavBar from "../../../../../../components/NavBar/NavBar";
import {useTranslation} from "react-i18next";
import LastPrice from "../LastPrice/LastPrice";
import RecentTxHistory from "../RecentTxHistory/RecentTxHistory";
import Wallet from "../Wallet/Wallet";
import {useGetKycStatus} from "../../../../../../query";
import {images} from "../../../../../../assets/images/images";
import {isMobile} from "react-device-detect";
import * as RoutesName from "../../../../../../Routes/routes";
import {Link, useNavigate} from "react-router-dom";
import {Authentication} from "../../../../../../Routes/routes";
import * as Routes from "../../../../../../Routes/routes";
import Icons from "../../../../../../components/Icon/Icons";
import i18n from "i18next";

const Content = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data: statusData, isLoading: statusIsLoading, error: statusError, refetch} = useGetKycStatus()


    const [step, setStep] = useState(1)

    const tab = [
        {id: 1, title: t("rates"), action:  () => setStep(1)},
        {id: 2, title: t("wallet"), action:() => setStep(2)},
        {id: 3, title: t("History.transactions"), action:() => setStep(3)},
    ];

    const content = () => {

        if (step === 1) {
            return <LastPrice/>
        }
        if (step === 2) {
            return <Wallet/>
        }
        if (step === 3) {
            return <RecentTxHistory/>
        }
    }
    
    const kycStatusContent = () => {
        if (statusData?.kyc_status === "NoKYC") {
            return <>
                <span className={`text-red width-60`}>{t("Authentication.NoKYC")}</span>
                <Link to={Routes.Authentication} className={`row jc-center ai-center cursor-pointer hover-text width-40 flex jc-end`}>
                    <span className={`${isMobile ? "ml-05" : " ml-025"}`}>{t("Authentication.start")}</span>
                    <Icons
                        iconName={`${i18n.language !== "fa" ? 'icon-right-open-1' : 'icon-left-open-1'} flex`}
                        className={`mr-05`}/>
                </Link>
            </>
        }
        if (statusData?.kyc_status === "Pending") {
            return <>
                <span className={`width-100`}>{t("Authentication.Pending")}</span>
               {/* <Link to={Routes.Authentication} className={`row jc-center ai-center cursor-pointer hover-text width-40 flex jc-end`}>
                    <span className={`${isMobile ? "ml-05" : " ml-025"}`}>{t("Authentication.start")}</span>
                    <Icons
                        iconName={`${i18n.language !== "fa" ? 'icon-right-open-1' : 'icon-left-open-1'} flex`}
                        className={`mr-05`}/>
                </Link>*/}
            </>
        }
        if (statusData?.kyc_status === "Rejected") {
            return <>
                <span className={`text-red width-62`}>{t("Authentication.Rejected")}</span>
                <Link to={Routes.Authentication} className={`row jc-center ai-center cursor-pointer hover-text width-38 flex jc-end`}>
                    <span className={`${isMobile ? "ml-05" : " ml-025"}`}>{t("Authentication.showReason")}</span>
                    <Icons
                        iconName={`${i18n.language !== "fa" ? 'icon-right-open-1' : 'icon-left-open-1'} flex`}
                        className={`mr-05`}/>
                </Link>
            </>
        }
      
    }

    return (
        <div className={`${classes.container} column jc-center ai-center`}>

            {/**/}

            {
                statusIsLoading ? <div className={`${classes.rectangle2} width-86 rounded-8 py-2 mb-4`}/> :
                    <>
                        { statusData?.kyc_status !== "Approved" && <div className={`width-86 rounded-8 row jc-between ai-center ${classes[statusData?.kyc_status]} ${isMobile ? "px-3" : "px-1"} py-2 mb-4 `}>

                            {kycStatusContent()}
                        </div>}
                    </>

            }


            <NavBar
                tab={tab}
                navClass={`width-86`}
            />

            {content()}

        </div>
    );
};

export default Content;
