import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

export const useGetTerminals = (gateway = "") => {
    const axiosPrivate =  useAxiosPrivate()
    return useQuery(
        ['terminals', gateway],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/gateways/${gateway}/terminals`)

            return data?.data;
        },
        {
            staleTime: 5 * 60 * 1000,
        }

    );
}