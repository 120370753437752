import React from 'react';
import Icons from "../../../../../../components/Icon/Icons";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {isBrowser} from "react-device-detect";

const HeaderBuilder = ({lead, content, after}) => {

    const navigate = useNavigate();

    const {global, setGlobal} = useGlobal();

    const direction = global?.direction


    const openGiftActionSheetHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType: "gift",
        })
    }

    const leadHandler = () => {
        if (lead === "back") return <Icons
            iconName={`${direction === 'ltr' ? 'icon-left-open' : 'icon-right-open'} flex`}
            iconClass={`cursor-pointer`}
            onClick={()=>navigate(-1)}
        />

        if (lead === "menu" && !isBrowser) return <Icons
            iconName="icon-menu flex"
            iconClass={`cursor-pointer`}
            //onClick={()=>setActive(prevstate => !prevstate)}
            //onClick={() => dispatch(activeActionSheet({subMenu: true}))}
            onClick={() => setGlobal({
                ...global,
                activeActionSheet: {
                    menu: true,
                    select: false,
                },
            }) }

        />
        if (lead === "home") return <Icons
            iconName={`${direction === 'ltr' ? 'icon-left-open' : 'icon-right-open'} flex`}
            iconClass={`cursor-pointer`}
            onClick={()=>{
                setGlobal({
                    ...global,
                    activeActionSheet: {
                        menu: true,
                        select: false,
                    },
                    selected_deposit_coin: null,
                    selected_deposit_network: null,
                    selected_exchange_base: null,
                    selected_exchange_quote: null,
                    selected_withdrawal_coin: null,
                    selected_withdrawal_network: null,
                })
                navigate(RoutesName.Home)
            }}
        />
        return <></>

    }
    const contentHandler = () => {

    }
    const afterHandler = () => {

        if (after === "chat") return <>
            <Icons
                iconName="icon-chat flex fs-04"
                iconClass={`cursor-pointer`}
                onClick={()=>navigate(RoutesName.Chat)}
            />
        </>
        if (after === "gift") return <>
            {global?.voucherSupport && <Icons
                iconName="icon-gift flex fs-04"
                iconClass={`cursor-pointer ml-05`}
                onClick={()=>openGiftActionSheetHandler()}
            />}
            <Icons
                iconName="icon-chat flex fs-04"
                iconClass={`cursor-pointer`}
                onClick={()=>navigate(RoutesName.Chat)}
            />
        </>
        return <></>

    }

    return (
        <div className={`width-100 row flex jc-center ai-center`}>
            <div className={`width-20 flex jc-start ai-center fs-03`}>{leadHandler()}</div>
            <div className={`width-60 flex jc-center ai-center font-weight-bold fs-02`}>{content}</div>
            <div className={`width-20 flex jc-end ai-center fs-03`}>{afterHandler()}</div>
        </div>
    );
};

export default HeaderBuilder;
