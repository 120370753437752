import {createContext, useEffect, useState} from "react";
import i18n from "i18next";
import {getCurrencies} from "../api/exchange";

const GlobalContext = createContext({});

export const GlobalProvider = ({children}) => {

    const [global, setGlobal] = useState({
        accessDenied: false,
        otpLock: null,
        hideUserBalance: (localStorage.getItem("hideUserBalance") === null) || (localStorage.getItem("hideUserBalance") === "true"),
        hasError : false,
        voucherSupport : false,
        isLoading : true,
        currencies: {},
        countries: [],
        defaultCountry: null,
        language: null,
        direction: null,
        datePickerLocale: null,
        calendar: null,
        refs: [],
        prices: {},
        activeActionSheet: {
            menu: false,
            select: false,
        },
        selectType: null,
        selected_deposit_coin: null,
        selected_deposit_network: null,

        selected_exchange_base: null,
        selected_exchange_quote: null,

        selected_withdrawal_coin: null,
        selected_withdrawal_network: null,
        selected_withdrawal_iban: null,

        selected_tx_h_coin: null,
        selected_tx_h_category: null,
        selected_tx_h_size: null,

        reserveOrderRes: null,


        selected_history_category: null,

        selected_transactions_history_currency: null,
        selected_transactions_history_size: null,

        selected_deposit_history_currency: null,
        selected_deposit_history_size: null,

        selected_withdraw_history_currency: null,
        selected_withdraw_history_size: null,

        selected_swap_history_source_currency: null,
        selected_swap_history_dest_currency: null,
        selected_swap_history_size: null,


        selected_deposit_sheba: null
    });

    useEffect(() => {
        if (global.otpLock) {
            localStorage.setItem("otpLock", global?.otpLock);
        }
        if (global.language) {
            i18n.changeLanguage(global.language);
            localStorage.setItem("language", global?.language);
        }
        localStorage.setItem("hideUserBalance", global?.hideUserBalance);
    }, [global]);

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                const response = await getCurrencies();
                const newCurrencies = response?.data?.data?.currencies;
                const newPrices = response?.data?.data?.prices;


                setGlobal(prevGlobal => ({
                    ...prevGlobal,
                    currencies: { ...newCurrencies },
                    prices: { ...newPrices }
                }));
            } catch (error) {
                console.error("Error fetching prices:", error);
            }
        }, 120000);

        return () => clearInterval(interval);
    }, []);

    return (
        <GlobalContext.Provider value={{global, setGlobal}}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalContext;