import React, {useRef, useState} from 'react';
import classes from './Gift.module.css'
import {Trans, useTranslation} from "react-i18next";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {BN, getCurrencyNameOrAlias, validateEmail} from "../../../../../../utils/utils";
import Button from "../../../../../../components/Button/Button";
import {submitVoucher} from "../../../../../../api/auth";
import toast from "react-hot-toast";
import Loading from "../../../../../../components/Loading/Loading";
import useAuth from "../../../../../../Hooks/useAuth";
import {images} from "../../../../../../assets/images/images";
import content from "../../../../Pages/Home/Module/Content/Content";
import i18next from "i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import MobileComp from "../../../../../../components/MobileComp/MobileComp";
import * as RoutesName from "../../../../../../Routes/routes";
import {useNavigate} from "react-router-dom";
import {TransactionsHistory} from "../../../../../../Routes/routes";

const Gift = () => {

    const {t} = useTranslation();

    const {auth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const language = global?.language

    const navigate = useNavigate();

    const [input, setInput] = useState({
        giftCode: {value: "", error: []},
    });

    const giftData = useRef(null);


    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }


        if (e.target.dataset?.type === "email" && !validateEmail(e.target.value)) {
            errorMessage.push(t('wrongEmail'))
        }
        if (e.target.dataset?.name === "confirmPassword" && e.target.value !== input.password.value) {
            errorMessage.push([t('wrongPasswordConfirmation')])
        }


        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }

        if (e.target.dataset?.name === "password") {
            prevState.confirmPassword.error = (e.target.value === input.confirmPassword.value || input.confirmPassword.value.length === 0) ? [] : [t('wrongPasswordConfirmation')]
        }

        setInput(prevState)
    }

    const isFormValid = () => {

        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }

    const submit = async (e) => {

        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)

        const submitVoucherParam = {
            "voucher": input.giftCode.value,
        }

        submitVoucher(submitVoucherParam, auth?.token)
            .then(async (res) => {

                console.log("res", res)

                giftData.current = res?.data?.data;

                setSuccess(true)
                setInput({
                    giftCode: {value: "", error: []},
                })




                /*toast.success(t("UpdateProfile.success"))
                refetch()*/


                /*setAuth({...auth,
                    token: res?.data?.data?.access_token,
                })*/

                /*navigate(RoutesName.Home)*/

            }).catch(err => {




            if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                toast.error(t("ErrorMessages."+ err?.response?.data?.message))
            }
            else {
                toast.error(t("serverError"))
            }

            /*   if (err?.response?.data?.data?.needPassword) {
                   navigate(RoutesName.Password)
               }
               else {
                   toast.error(err?.response?.data?.message)
               }*/

        }).finally(() => {

            setLoading(false)

        })

        /*return navigate(RoutesName.UploadImages)*/
    }

    console.log("giftData", giftData)

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    const content = () => {
        if (success) return <div className={`width-100 height-100 column jc-between ai-center`}>
            <span className={`font-weight-bold fs-02`}>{t("Gift.title")}</span>
            <div className={`width-100 column jc-center ai-center`}>
                <img className={`${classes.image}`} src={images.giftOpen} alt="gift"/>
                <span className={`text-green`}>{t("Gift.success")}</span>
                <div className={`width-86 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-2`}>
                    <span className={`width-40`}>{t("Gift.amount")}</span>
                    <div className={`width-60 row jc-end ai-center`}>
                        <span className={`fs-02 font-weight-bold`}>{new BN(giftData?.current?.amount).decimalPlaces(global?.currencies[giftData?.current?.currency]?.precision).toNumber()}</span>
                        <span className={`${classes.space}`}>{getCurrencyNameOrAlias(global?.currencies[giftData?.current?.currency], language)}</span>
                    </div>
                </div>
                <div className={`width-86 row jc-center ai-center`}>
                    {t("Gift.description")}: {giftData?.current?.description}
                </div>
            </div>



            <div className={`width-100 row jc-around ai-center`}>
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} ${classes.background} width-45 cursor-pointer rounded-100-p my-1`}
                    buttonTitle={t("txHistory")}
                    onClick={()=> {
                        navigate(RoutesName.TransactionsHistory)
                    }}
                />
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} ${classes.border} width-45 cursor-pointer rounded-100-p my-1`}
                    buttonTitle={t("close")}
                    onClick={()=> {
                        setGlobal({
                            ...global,
                            activeActionSheet: {
                                menu: false,
                                select: false,
                            },
                            selectType: null
                        })
                    }}
                />
            </div>
        </div>
        return <form onSubmit={(e)=>submit(e)} className={`width-100 height-100 column jc-between ai-center`}>
            <span className={`font-weight-bold fs-02`}>{t("Gift.title")}</span>
            <div className={`column jc-center ai-center width-100`}>
                <img className={`${classes.image}`} src={images.giftClose} alt="gift"/>
                <span className={`my-1`}>{t("Gift.enterGiftcode")}</span>
                <TextInput
                    value={input.giftCode.value}
                    type="text"
                    label={t('giftCode')}
                    id="giftCode"
                    labelFor="giftCode"
                    //placeholder={t('Login.mobilePh')}
                    data-name="giftCode"
                    data-type="input"
                    data-min={6}
                    maxLength="20"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.giftCode.error}
                    inputClass={`width-85 my-1`}
                />
            </div>
            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading || !input?.giftCode?.value}
            />
        </form>
    }

    return (
        content()
    );
};

export default Gift;
