import React, {lazy, Suspense,  useEffect, useState} from 'react';
import classes from "./OffChainDeposit.module.css"
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetUserWallet} from "../../../../../../query";
import TextInput from "../../../../../../components/TextInput/TextInput";


const OffChainDeposit = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();
    const {auth} = useAuth();
    const gateway = global?.selected_deposit_network
    const transferMethod = gateway?.transferMethod

    const [DynamicComponent, setDynamicComponent] = useState(null);
    const [hasError, setHasError] = useState(false);


    useEffect(() => {
        if (!transferMethod) return;
        setHasError(false);

        import(`../${transferMethod}/${transferMethod}.js`)
            .then((module) => {
                setDynamicComponent(() => lazy(() => Promise.resolve({ default: module.default })));
                setHasError(false);
            })
            .catch((error) => {
                console.warn(`❌ Component not found for method: ${transferMethod}`);
                setHasError(true);
                setDynamicComponent(null);
            });
    }, [transferMethod]);

    const [loading, setLoading] = useState(false)

    const [input, setInput] = useState({
        transferMethod: {value: "", error: []},
    });

    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError, refetch:refetchUserWallet} = useGetUserWallet()

    useEffect(()=>{
        let newInputData = {...input}
        if (gateway) {
            newInputData = {...newInputData,
                transferMethod : { value: gateway?.transferMethod, error: []},
            }
        }
        setInput(newInputData)
    },[global?.selectType])

    const precision = global?.currencies[gateway?.currencySymbol]?.precision
    const freeWallet = !userWalletIsLoading && userWalletData[gateway?.currencySymbol]?.balance || 0

    const minDeposit = gateway?.depositMin
    const maxDeposit = gateway?.depositMax
    const DepositFee = gateway?.withdrawFee
    /*const withdrawEnabled = !isLoading && data?.currencyImpData?.implementations[0]?.withdrawEnabled*/
    const depositEnabled = gateway?.depositAllowed


    return (
        <>
            <TextInput
                value={input.transferMethod.value ? t("TransferMethod." + input.transferMethod.value) : input.transferMethod.value}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('transferMethod')}
                //placeholder={t('Login.mobilePh')}
                data-name="transferMethod"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.transferMethod.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                selectType="deposit_network"
            />
            {hasError ? (
                <span className={`my-10`}>{t("noData")}</span>
            ) : DynamicComponent ? (
                <Suspense fallback={<>
                    <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                    <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                    <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                </>}>
                    <DynamicComponent />
                </Suspense>
            ) : (
                <p></p>
            )}
        </>
    );
};

export default OffChainDeposit;
